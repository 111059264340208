import React from "react";
import Portrait5 from "../assets/images/leena.jpg";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-5/6 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            <br className="hidden lg:inline-block" />
            Hey there - Welcome to my portfolio website!
          </h1>
          <p className="mb-8 leading-relaxed">
            I'm a passionate tech developer driven by creating impactful
            products. I thrive on empowering users to achieve their goals,
            fostering transparency in markets, and delivering exceptional user
            experiences. Currently, I'm focused on WealthTech, where I'm
            building tools to help people take charge of their financial
            well-being.
          </p>
          <p className="mb-8 leading-relaxed">
            Beyond tech, I'm a health and wellness enthusiast. I'm constantly
            exploring ways to promote a happier and healthier lifestyle, whether
            through exercise, nutrition, mindfulness, or strong social
            connections. In my free time, you'll likely find me "logging off" to
            connect with loved ones, enjoy the outdoors, or get some exercise.
            This passion for well-being fuels my interest in future HealthTech
            ventures.
          </p>
          <p className="mb-8 leading-relaxed">
            This website started as a personal project to master Tailwind and
            GCP/Firebase hosting. It also serves as a window into my skillset
            and experience. Feel free to reach out!
          </p>
          <div className="flex justify-center">
            <a
              href="#projects"
              className="inline-flex text-white bg-red-400 border-0 py-2 px-6
                            focus:outline-none hover:bg-red-800 rounded text-sm md:text-md"
            >
              See my work
            </a>
            <a
              href="#experience"
              className="ml-4 inline-flex text-white bg-red-400 border-0 py-2 px-6
                            focus:outline-none hover:bg-red-800 rounded text-sm md:text-md"
            >
              Peek at my CV
            </a>
            <a
              href="#contact"
              className="ml-4 inline-flex text-white bg-red-400 border-0 py-2 px-6
                            focus:outline-none hover:bg-red-800 rounded text-sm md:text-md"
            >
              Get in touch!
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full w-1/2">
          <img
            className="object-cover object-center object-scale-down rounded-full"
            alt="hero"
            src={Portrait5}
          />
        </div>
      </div>
    </section>
  );
}
