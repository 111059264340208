import './App.css';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Contact2 from './components/Contact2';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
import Education from './components/Education';
import Languages from './components/Languages';

function App() {
  return (
    <div className="text-gray-400 bg-black body-font">
      <Navbar />
      <About />
      <Projects />
      <Experience />
      <Education />
      <Skills />
      <Languages />
      <Contact2 />
    </div>
  );
}

export default App;
