export const experience = [
  {
    role: "Product Manager",
    company: "Additiv AG",
    location: "Zurich, Switzerland",
    date: "Feb 2022 - Present",
    longDesc:
      "Driving groundbreaking UX for native wealth, credit, and insurance apps, empowering users to take control of their finances.",
    tags: "Product Management, Native Apps, User Experience, Project Management, Stakeholder Management",
  },
  {
    role: "Management Consulting Manager",
    company: "Accenture",
    location: "London, UK & Helsinki, Finland",
    date: "Nov 2017 - Dec 2021",
    longDesc:
      "Helped organisations to drive complex digital transformations and ensuring ROI for their technology investments. My key focus was within financial, technology and telecommunication industries, and I gained a vast practical experience working cross-functionally and driving change.",
    tags: "Project Management, Product Management, User Experience, Analytics, Change Management, Stakeholder Management",
  },
  {
    role: "Senior Sales Manager",
    company: "Nordea Markets",
    location: "Helsinki, Finland & New York, US & Singapore",
    date: "Mar 2012 - Sep 2017",
    longDesc:
      "Provided financial risk management advisory and foreign exchange execution for large corporations and institutions. Led and participated in projects driving trading automation and regulatory adaptation. International assignments to drive global business development.",
    tags: "Sales, Investment Banking, Customer Relationship Management, Business Development",
  },
];

export const education = [
  {
    school: "Imperial College London",
    degree: "MSc in Computing Science",
    year: "2021",
    gpa: "Distinction",
  },
  {
    school: "Aalto University School of Business",
    degree: "MSc in Economics",
    year: "2014",
    gpa: "4/5",
  },
  {
    school: "Aalto University School of Business",
    degree: "BSc in Economics",
    year: "2013",
    gpa: "4.5/5",
  },
];
