import { AcademicCapIcon } from "@heroicons/react/solid";
import React from "react";
import { education } from "../cv";


export default function Education() {
    return (
        <section id="experience">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-10">
                    <AcademicCapIcon className="w-10 inline-block mb-4"/>
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Education
                    </h1>
                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        
                    </p>

                </div>
       
                <div className="flex justify-center flex-wrap">
                    {education.map((item) => (
                            <div className="flex relative">
                                <div className="px-8 py-8 relative m-1 w-96 border-2 border-gray-800">
                                    <h2 className="text-sm title-font font-medium text-red-300 mb-1">
                                        {item.school} 
                                    </h2>
                                    <h1 className="title-font text-lg font-medium text-white">
                                        {item.degree}
                                    </h1>
                                    <p className="title-font text-md font-thin text-white mb-3">
                                        {item.year}
                                    </p>        
                                    <p className="leading-relaxed text-sm">
                                        GPA: {item.gpa}
                                    </p>     
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </section>
    );
}