
import { AtSymbolIcon, LocationMarkerIcon, MailOpenIcon, LinkIcon, CodeIcon } from "@heroicons/react/solid";
import React from "react";

export default function Contact2() {

    return (
        <section id="contact">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-10">
                    <AtSymbolIcon className="w-10 inline-block mb-4" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Contact Me
                    </h1>
                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        If you want to get in touch, send me an email or let's connect on LinkedIn!
                    </p>
                </div>
               
                <div className="flex justify-center">
                <div className="bg-gray-900 relative py-6 rounded shadow-md w-full md:w-2/3 lg:w-1/2">
                    <p className=" p-5">
                        <LocationMarkerIcon className="w-5 text-red-300 inline-block mb-2 mr-2" /> <strong> Location: </strong> Zurich, Switzerland
                     </p>
                    <p className="p-5">
                        <MailOpenIcon className="w-5 text-red-300 inline-block mb-2 mr-2" /> <strong> Email: </strong> leena.poikolainen@gmail.com
                    </p>
                    <p className="p-5">
                        <LinkIcon className="w-5 text-red-300 inline-block mb-2 mr-2" /> <strong> Linkedin: </strong> 
                        <a href="https://linkedin.com/in/leenapoikolainen" target="_blank">linkedin.com/in/leenapoikolainen</a>
                    </p>
                    <p className="p-5">
                        <CodeIcon className="w-5 text-red-300 inline-block mb-2 mr-2" /> <strong> GitHub: </strong> 
                        <a href="https://github.com/leenapoikolainen" target="_blank">github.com/leenapoikolainen</a>
                    </p>
                </div>
                </div>
            </div>
            
        </section>
    );
}