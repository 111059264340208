import { BriefcaseIcon } from "@heroicons/react/solid";
import React from "react";
import { experience} from "../cv";


export default function Experience() {
    return (
        <section id="experience">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-10">
                    <BriefcaseIcon className="w-10 inline-block mb-4"/>
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Work Experience
                    </h1>
                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        Here is a short summary of my key work experience. To see more,
                    </p>
                    <p className="underline text-white">
                        <a href="https://uk.linkedin.com/in/leenapoikolainen" target="_blank">Check out my LinkedIn profile</a>
                    </p>
                </div>
                <div className="flex flex-col">
                    {experience.map((item) => (
                            <div className="flex relative">
                                <div className="px-8 py-8 relative mb-2 w-full border-2 border-gray-800 bg-black">
                                    <h2 className="tracking-widest text-sm title-font font-medium text-red-300 mb-1">
                                        {item.company} - {item.location}
                                    </h2>
                                    <h1 className="title-font text-lg font-medium text-white">
                                        {item.role}
                                    </h1>
                                    <p className="title-font text-md font-thin text-white mb-3">
                                        {item.date}
                                    </p>        
                                    <p className="leading-relaxed text-sm">
                                        {item.longDesc}
                                    </p>
                                    <p className="text-md mt-2 mb-2 text-white underline">
                                        Key skills:
                                    </p>
                                    <p className="text-sm">
                                        {item.tags}
                                    </p>
                                    
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </section>
    );
}