import { ChatIcon, TranslateIcon} from "@heroicons/react/solid";
import React from "react";
import { languages } from "../data";

export default function Languages() {
    return(
        <section id="languages">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-20">
                    <TranslateIcon className="w-10 inline-block mb-4"/>
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Spoken languages
                    </h1>
                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mb-2">
                        I'm originally from Finland and have lived abroad in several countries. I love learning new languages 
                        and wish I could use them more (albeit my strength has been grammar over fluency which I am aiming to change!).
                    </p>
                    <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                        {languages.map((language) => (
                            <div key={language} className="p-2 sm:w-1/2 w-full">
                                <div className="bg-black rounded flex p-4 h-full items-center">
                                    <ChatIcon className="text-red-300 w-6 h-6 flex-shrink-0 mr-4" />
                                    <span className="title-font font-medium text-white">
                                        {language.name} - <i>{language.level}</i>

                                    </span>
                                </div>
                            </div>   
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}