import platform from "./assets/images/BondPlatform.png";
import puuApua from "./assets/images/puuApua.png";
import donationPlatform from "./assets/images/donation_platform.png";

export const projects = [
  {
    title: "Blockchain-based Green Bond Platform",
    subtitle: "Ethereum",
    description:
      "A DApp built on top of a public Ethereum testnet (Ropsten). Allows issuing Green Certifications, Green Verifications and Green Bond NFTs. Supports bond price bidding, bond investments, performance-based coupon adjustments and blockchain-based payments.",
    image: platform,
    link: "https://storage.googleapis.com/greenbonds_production/index.html",
    target: "_blank",
  },
  {
    title: "Milk Donation Portal",
    subtitle: "Python, Bootstrap, HTML/CSS",
    description:
      "A school project where our team built a prototype for a milk donation portal for a UK-based NGO that offers access to supplies of donor human milk to hospitals and community-based clinicians. Platform backend was built with Python and the portal is integrated with a SQL database.",
    image: donationPlatform,
    link: "https://www.leena.dev/#projects",
    target: "_self",
  },
  {
    title: "Website for a forest company",
    subtitle: "React, Tailwind, Firebase",
    description:
      "A website to advertise the services of a small forest company in Finland. The website is built with React, styled with Tailwind and hosted on Firebase.",
    image: puuApua,
    link: "https://puuapua.fi",
    target: "_blank",
  },
];

export const skills = [
  {
    name: "Ethereum & Solidity",
  },
  {
    name: "C++",
  },
  {
    name: "React",
  },
  {
    name: "HTML & CSS",
  },
  {
    name: "Java",
  },
  {
    name: "Python",
  },
  {
    name: "SQL",
  },
  {
    name: "Agile Delivery",
  },
  {
    name: "Product Management",
  },
  {
    name: "Stakeholder Management",
  },
];

export const languages = [
  {
    name: "Finnish",
    level: "Native",
  },
  {
    name: "English",
    level: "Full professional proficiency",
  },
  {
    name: "German",
    level: "Conversational",
  },
  {
    name: "Swedish",
    level: "Conversational",
  },
  {
    name: "Italia",
    level: "Basics",
  },
];
